import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useRouter } from '../assets/scripts/hooksLib';
import { onError } from '../assets/scripts/errorLib';
import '../assets/styles/login.scss';

/**
 * User login form
 * @returns {HTMLElement} html for login block
 */
export default function Login() {
    const router = useRouter();
    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const [user, setUser] = useState(null);

    // load user authentication state
    useEffect(() => {
        let isMounted = true;

        const onLoad = async () => {
            try {
                // set the current/live canonical location url
                const location = window.location,
                    livelocation = `${location.protocol}//${location.host}${location.pathname}`,
                    canonical = document.querySelector('link[rel="canonical"]');
                canonical.setAttribute('href', livelocation);

                const user = await Auth.currentSession();

                userHasAuthenticated(true);
                setUser(user);
            } catch (e) {
                if (e !== 'No current user') {
                    onError(e);
                }
            }
        }

        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, [setUser, userHasAuthenticated]);

    /**
     * Log off website
     * @returns {void}
     */
    const handleLogout = async () => {
        await Auth.signOut();

        userHasAuthenticated(false);

        router.history('/');
        scrollTop();
    };

    return (
        isAuthenticated ?
            <>
                {user ?
                    <div className="nav-item">
                        <a href='../me' className='nav-link' rel='nofollow'>
                            {user.idToken.payload.name}
                        </a>
                    </div>
                    :
                    ''
                }
                <div className="nav-item" title='Logout'>
                    <a href='../logout.html' onClick={handleLogout} className='nav-link' rel='nofollow'>
                        <i className="fa-solid fa-power-off">&nbsp;</i>
                    </a>
                </div>
            </>
            : <>
                <div className="nav-item">
                    <a href="/signup.html" data-rr-ui-event-key="/signup.html" className="nav-link" rel="nofollow">Signup</a>
                </div>
                <div className="nav-item">
                    <a href="/login.html" data-rr-ui-event-key="/login.html" className="nav-link" rel="nofollow">Login</a>
                </div>
            </>
    );
}